<template>
  <div class="form-content-container">
    <Form class="form-container">
      <div class="form-item">
        <div class="title">
          <Input
            placeholder="请输入标题(5-30字)"
            v-model.trim="formValidate.title"
            maxlength="30"
          />
        </div>
        <div class="number">{{ formValidate.title.length }}/30</div>
      </div>
      <FormAuther
        @autherInfo="handleAuther"
        @autherInfo1="handleAuther1"
        @autherInfo2="handleAuther2"
        :formValidate="formValidate"
      ></FormAuther>
      <div class="form-item">
        <Input
          placeholder="请输入关键词，多个关键词之间用英文逗号“,”隔开"
          v-model.trim="formValidate.keyword"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          type="textarea"
          :rows="4"
          placeholder="请输入摘要"
          v-model.trim="formValidate.artSummary"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          placeholder="请输入出版物名称"
          v-model.trim="formValidate.publication"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <DatePicker
          type="date"
          placeholder="请输入出版时间"
          style="width: 200px"
          @on-change="changeTime"
          :value="formValidate.publishTime"
          :editable="false"
        ></DatePicker>
        <span class="xing">*</span>
      </div>
      <div class="form-item" @click="handleFileDel">
        <div class="upload">
          <label for="">附件</label>
          <Upload
            type="drag"
            :action="actionUrl"
            :format="['pdf']"
            :headers="{ token: token }"
            :on-success="handleSuccess"
            accept=".pdf"
            :show-upload-list="false"
          >
            <Button>上传</Button>
            <p style="margin-left: 7px" v-if="!formValidate.accessoryName">
              请上传pdf格式文件
            </p>
            <p style="margin-left: 7px" v-else>
              {{ formValidate.accessoryName }}
            </p>
          </Upload>
        </div>
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          v-model.trim="formValidate.literatureUrl"
          placeholder="请输入外部链接便于平台审核"
        />
      </div>
      <div class="form-item">
        <div class="checkbox">
          <label for="">发布栏目</label>
          <CheckboxGroup v-model="columnNum">
            <Checkbox disabled :label="columnNum[0]"></Checkbox>
          </CheckboxGroup>
        </div>
      </div>
    </Form>
    <div class="submit-container">
      <div class="btns">
        <Button
          :disabled="disableFlag"
          @click="handlePreview(0, '/conferenceotherpreview')"
          >预览</Button
        >
        <Button @click="handleSave(formValidate)" v-throttleClick="2000">保存</Button>
        <Button @click="handleSubmit(formValidate)" v-throttleClick="2000">发布</Button>
      </div>
    </div>
  </div>
</template>

<script>
import FormAuther from "../components/FormAuther.vue";
import { mapState } from "vuex";
import preview from "@/mixins/preview.js";
export default {
  components: {
    FormAuther,
  },
  mixins: [preview],
  created() {
    // 认证
    if (this.userInfo.user.customerType === 1) {
      this.isFlag = true;
    }
    if (this.$route.query.id) {
      this.getEcho(this.formValidate);
      this.disableFlag = false;
    }
  },
  data() {
    return {
      isFlag: false,
      actionUrl: `${this.$global.url1}/zw-public/web/common/upload`, // 接口路径
      formValidate: {
        firstAuther: "",
        firstUnit: "",
        firstUnitId: "",
        communicationAuthor: "",
        communicationAuthorUnit: "",
        communicationAuthorUnitId: "",
        title: "",
        keyword: "",
        artSummary: "",
        autherList: [
          {
            authorName: "",
            authorUnit: "",
            showSelect1: false,
            unitDisabled1: true,
            authorUnitId: "",
            authorId: "",
          },
        ],
        articleAuthorList: [], // 其他作者
        filePath: "", // 文件路径
        accessoryName: "", // 文件名称
        synchronizedStatus: false,
        literatureUrl: "",
        publication: "",
        publishTime: "",
      },
    };
  },
  computed: {
    ...mapState(["token", "userInfo"]),
    columnNum() {
      let arr = [];
      if (sessionStorage.getItem("isSon") === "false") {
        if (this.userInfo.user.customerType === 4) {
          arr = ["百科号"];
        } else if (this.userInfo.user.customerType === 5) {
          arr = ["机构号"];
        } else if (
          this.userInfo.user.customerType === 1 ||
          this.userInfo.user.customerType === 2
        ) {
          arr = ["百家号"];
        }
      } else {
        arr = ["学者空间"];
      }
      return arr;
    },
  },
  methods: {
    verification(formValidate) {
      if (this.formValidate.title.length < 5) {
        this.$Message.error("标题不能少于五个字");
        return false;
      }
      if (!/[a-zA-Z0-9]+/g.test(this.formValidate.classifyNumber)) {
        this.$Message.error("只能是数字和字母");
        return false;
      }
      let reg = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~])+$/;
      if (!reg.test(formValidate.literatureUrl) && formValidate.literatureUrl !== '') {
        this.$Message.error("外部链接格式不正确");
        return false;
      }
      // 分隔
      if (/^,|[，。？！.?!]+|(,,)+|,$/.test(formValidate.keyword)) {
        this.$Message.error("多个关键词，只能以英文逗号分隔");
        return false;
      } else {
        let arr = formValidate.keyword.split(",");
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] == "") {
            this.$Message.error("关键词中存在多余英文逗号");
            return false;
          }
        }
      }
      if (formValidate.artSummary.length > 2500) {
        this.$Message.error("内容简介超出限制");
        return false;
      }
      return true;
    },
    // 回显
    async getEcho(f) {
      const resp = await this.$apis.formServe.getEcho({
        articleId: this.$route.query.id,
        articleType: 0,
      });
      console.log(resp);
      const r = resp.data.data;
      f.title = r.title;
      f.keyword = r.keyword;
      f.artSummary = r.artSummary;
      f.filePath = r.filePath; // 文件路径
      f.accessoryName = r.accessoryName; // 文件名称
      f.firstAuther = r.firstAuthor;
      f.firstUnit = r.firstAuthorUnit;
      f.communicationAuthor = r.communicationAuthor;
      f.communicationAuthorUnit = r.communicationAuthorUnit;
      f.literatureUrl = r.literatureUrl;
      // 其他作者
      this.tempAutherList = r.articleAuthorList;
      for (var i = 0; i < r.articleAuthorList.length - 1; i++) {
        f.autherList.push({
          authorName: "",
          authorUnit: "",
          showSelect1: false,
          unitDisabled1: true,
          authorUnitId: "",
          authorId: "",
        });
      }
      r.articleAuthorList.forEach((vv, index) => {
        f.autherList[index].authorName = vv.authorName;
        f.autherList[index].authorUnit = vv.authorUnit;
        f.autherList[index].authorId = vv.authorId;
        f.autherList[index].authorUnitId = vv.authorUnitId;
      });
      f.synchronizedStatus = r.synchronizedStatus == 1 ? true : false;
      f.publication = r.publication;
      f.publishTime = r.publishTime;
    },
    async handleSave(formValidate) {
      if (this.isEmpty(formValidate)) {
        return false;
      }
      // 认证
      // if (this.userInfo.user.customerType === 1) {
      //   this.isFlag = true;
      //   return;
      // }
      if (!this.verification(formValidate)) {
        return false;
      }
      const resp = await this.$apis.formServe.webSave({
        auditStatus: 1,
        articleType: 0,
        type: 4,
        projectId:
          JSON.parse(sessionStorage.getItem("Table")) &&
          JSON.parse(sessionStorage.getItem("Table")).PlatformEnum[2].id,
        title: formValidate.title,
        keyword: formValidate.keyword,
        artSummary: formValidate.artSummary,
        filePath: formValidate.filePath, // 文件路径
        accessoryName: formValidate.accessoryName, // 文件名称
        articleAuthorList:
          formValidate.articleAuthorList.length > 0
            ? formValidate.articleAuthorList
            : this.tempAutherList, // 其他作者
        firstAuthor: formValidate.firstAuther, // 第一作者
        firstAuthorUnit: formValidate.firstUnit,
        firstAuthorUnitId: formValidate.firstUnitId,
        firstAuthorId: formValidate.firstAutherId,
        communicationAuthor: formValidate.communicationAuthor, // 通讯作者
        communicationAuthorUnit: formValidate.communicationAuthorUnit,
        communicationAuthorUnitId: formValidate.communicationAuthorUnitId,
        communicationAuthorId: formValidate.communicationAuthorId,
        articleId: this.$route.query.id || this.previewData.articleId,
        synchronizedStatus: formValidate.synchronizedStatus === true ? 1 : 0,
        literatureUrl: formValidate.literatureUrl,
        publication: formValidate.publication,
        publishTime: formValidate.publishTime,
      });
      if (resp.status === 200) {
        this.previewData = resp.data.data;
        this.$Message.success(resp.data.resultDesc);
      } else {
        this.$Message.error(resp.data.resultDesc);
      }
      this.disableFlag = false;
      return true;
    },
    async handleSubmit(formValidate) {
      if (this.isEmpty(formValidate)) {
        return false;
      }
      // 认证
      if (this.userInfo.user.customerType === 1) {
        this.isFlag = true;
        return;
      }
      if (!this.verification(formValidate)) {
        return;
      }
      const resp = await this.$apis.formServe.webSave({
        auditStatus: 0,
        articleType: 0,
        type: 4,
        projectId:
          JSON.parse(sessionStorage.getItem("Table")) &&
          JSON.parse(sessionStorage.getItem("Table")).PlatformEnum[2].id,
        title: formValidate.title,
        keyword: formValidate.keyword,
        artSummary: formValidate.artSummary,
        filePath: formValidate.filePath, // 文件路径
        accessoryName: formValidate.accessoryName, // 文件名称
        articleAuthorList:
          formValidate.articleAuthorList.length > 0
            ? formValidate.articleAuthorList
            : this.tempAutherList, // 其他作者
        firstAuthor: formValidate.firstAuther, // 第一作者
        firstAuthorUnit: formValidate.firstUnit,
        firstAuthorUnitId: formValidate.firstUnitId,
        firstAuthorId: formValidate.firstAutherId,
        communicationAuthor: formValidate.communicationAuthor, // 通讯作者
        communicationAuthorUnit: formValidate.communicationAuthorUnit,
        communicationAuthorUnitId: formValidate.communicationAuthorUnitId,
        communicationAuthorId: formValidate.communicationAuthorId,
        articleId: this.$route.query.id || this.previewData.articleId,
        synchronizedStatus: formValidate.synchronizedStatus === true ? 1 : 0,
        literatureUrl: formValidate.literatureUrl,
        publication: formValidate.publication,
        publishTime: formValidate.publishTime,
      });
      this.clearField(); // 清空
      if (resp.status === 200) {
        this.$Message.success(resp.data.resultDesc);
      }
      this.$router.push("/user"); //
      console.log(resp);
    },
    changeTime(obj) {
      this.formValidate.publishTime = obj;
    },
    // 文件删除处理
    handleFileDel(e) {
      if (
        e.target.className ===
        "ivu-icon ivu-icon-ios-close ivu-upload-list-remove"
      ) {
        this.formValidate.filePath = ""; // 文件路径
        this.formValidate.accessoryName = ""; // 文件名称
      }
    },
    handleSuccess(res, file) {
      const MaxSize = 1024 * 1024 * 20;
      if (file.size > MaxSize) {
        this.$Message.error("文件太大，请重新上传");
      }
      this.formValidate.filePath = res.content.url;
      this.formValidate.accessoryName = file.name;
    },
    handleAuther2(name, unit, orgId, userId) {
      this.formValidate.communicationAuthor = name;
      this.formValidate.communicationAuthorUnit = unit;
      this.formValidate.communicationAuthorUnitId = orgId;
      this.formValidate.communicationAuthorId = userId;
    },
    handleAuther1(list) {
      console.log(list);
      this.formValidate.articleAuthorList = list;
    },
    handleAuther(name, unit, orgId, userId) {
      this.formValidate.firstAuther = name;
      this.formValidate.firstUnit = unit;
      this.formValidate.firstUnitId = orgId;
      this.formValidate.firstAutherId = userId;
    },
    clearField() {
      // 清空
      this.formValidate.firstAuther = "";
      this.formValidate.firstUnit = "";
      this.formValidate.firstUnitId = "";
      this.formValidate.communicationAuthor = "";
      this.formValidate.communicationAuthorUnit = "";
      this.formValidate.communicationAuthorUnitId = "";
      this.formValidate.title = "";
      this.formValidate.keyword = "";
      this.formValidate.artSummary = "";
      this.formValidate.articleAuthorList = []; // 其他作者
      this.formValidate.filePath = ""; // 文件路径
      this.formValidate.accessoryName = ""; // 文件名称
      this.formValidate.synchronizedStatus = false;
      this.formValidate.literatureUrl = "";
      this.formValidate.publication = "";
      this.formValidate.publishTime = "";
    },
    isEmpty(f) {
      if (
        f.title === "" &&
        f.firstAuther === "" &&
        f.firstUnit === "" &&
        f.keyword === "" &&
        f.artSummary === "" &&
        f.accessoryName === "" &&
        f.publication === "" &&
        f.publishTime === ""
      ) {
        this.$Message.error("不能为空");
        return true;
      } else if (f.title === "") {
        this.$Message.error("标题不能为空");
        return true;
      } else if (f.firstAuther === "") {
        this.$Message.error("第一作者不能为空");
        return true;
      } else if (f.firstUnit === "") {
        this.$Message.error("第一作者单位不能为空");
        return true;
      } else if (f.keyword === "") {
        this.$Message.error("关键词不能为空");
        return true;
      } else if (f.artSummary === "") {
        this.$Message.error("摘要不能为空");
        return true;
      } else if (f.accessoryName === "") {
        this.$Message.error("附件不能为空");
        return true;
      } else if (f.publication === "") {
        this.$Message.error("出版物不能为空");
        return true;
      } else if (f.publishTime === "") {
        this.$Message.error("出版时间不能为空");
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ivu-tree ul li {
  margin: 0;
}
::v-deep .ivu-tree-children li {
  display: flex;
}
::v-deep .ivu-tree-children li .ivu-tree-arrow {
  display: none;
}
::v-deep .ivu-tree-children li .ivu-tree-children li {
  margin: 30px 40px 0 -60px;
}
::v-deep .ivu-tree-children li .ivu-tree-title {
  width: 100px;
}
::v-deep .ivu-radio-group {
  white-space: nowrap;
}
.form-content-container {
  margin: 38px auto 0;
  position: relative;
}
.ivu-input-wrapper ::v-deep input {
  border: none;
  background-color: #fff;
  height: 38px;
}
.ivu-input-wrapper ::v-deep input::-webkit-input-placeholder,
.ivu-input-wrapper ::v-deep textarea::-webkit-input-placeholder {
  color: #999;
}
.ivu-upload ::v-deep .ivu-upload-drag {
  border: none;
  display: flex;
  align-items: center;
}
::v-deep .ivu-upload {
  height: 38px;
  display: flex;
  align-items: center;
}
.ivu-input-wrapper ::v-deep textarea {
  border: none;
}
.form-container {
  width: 1200px;
  min-height: 1228px;
  background-color: #fff;
  padding: 60px 100px 0;
  margin: 4px auto 30px;
  box-shadow: 0px 7px 15px 1px rgba(22, 26, 100, 0.13);
  .form-item {
    width: 100%;
    min-height: 85px;
    padding: 25px 0;
    border-bottom: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #999;
    position: relative;
    &:not(:last-child) {
      border-bottom: 1px solid #dcdcdc;
    }
    .title {
      width: 900px;
      height: 38px;
      line-height: 38px;
      position: relative;
      ::v-deep input {
        font-size: 36px;
      }
      .right {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .xing {
      width: 30px;
      color: red;
      text-align: center;
    }
    .left,
    .right {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    .left {
      border-right: 1px solid #dcdcdc;
      width: 355px;
    }
    .right {
      margin-left: 10px;
      width: 642px;
    }
    .year,
    .center,
    .stage {
      display: flex;
      align-items: center;
    }
    .year {
      width: 299px;
      border-right: 1px solid #dcdcdc;
    }
    .center {
      width: 323px;
      border-right: 1px solid #dcdcdc;
    }
    .stage {
      width: 374px;
    }
    .checkbox {
      width: 970px;
      display: flex;
      padding-left: 7px;
      label {
        margin-right: 20px;
      }
    }
    .upload {
      display: flex;
      padding-left: 7px;
      align-items: center;
      label {
        margin-right: 20px;
      }
    }
  }
}
.submit-container {
  height: 70px;
  background-color: #fff;
  text-align: center;
  line-height: 70px;
  .btns {
    margin-left: 800px;
  }
  button {
    width: 90px;
    height: 36px;
    margin: 0 10px;
    border: 1px dashed #333333;
    border-radius: 8px;
    &:last-child {
      background: #00a4ff;
      border: 1px solid #00a4ff;
      border-radius: 8px;
      color: #fff;
    }
  }
}
</style>
